@import "../Global.scss";

.gallery-container {
  border: 1px solid rgba(89, 184, 218, 0.56);
  padding: 20px;
  //width: 1200px;
  .gallery-grid-item {

    display: inline-block;
    position: relative;
    overflow: hidden; /* required */
    cursor: pointer;
    text-align: center;
    border: 3px solid rgb(255, 255, 255);

    img {
      width: 400px;
    }

    .gallery-picture-description {
      //border: 2px solid rgb(0, 51, 255);
      padding: 20px 10px 20px 10px;
      //font-size: 14px;
      font-size: 1rem;
      color: #6d7377;
      font-family: 'roboto', Helvetica, Arial, sans-serif;
      text-transform: uppercase;

      .gallery-text1 {
        text-align: left;
        //border: 2px solid rgb(218, 25, 25);
        color: #c65376;
        font-weight: bold;
      }
      .gallery-text2 {
        text-align: left;
        //border: 2px solid rgb(0, 255, 21);
        //font-weight: bold;
      }
      .gallery-text3 {
        text-align: left;
        //border: 2px solid rgb(0, 51, 255);
        font-style: italic;
      }

      .gallery-text4 {
        text-align: left;
        //border: 2px solid rgb(0, 51, 255);
        //font-style: italic;
        font-weight: bold;
      }
    }

    .shape-shop-image-sash {
      margin: 0;
      //padding: 0;
      //padding-left: 100px;
      background: rebeccapurple;
      color:white;
      padding:1em 0;
      position: absolute;
      top:0;
      right:0;
      transform: translateX(30%) translateY(0%) rotate(45deg);
      transform-origin: top left;
    }
    .shape-shop-image-sash:before,
    .shape-shop-image-sash:after {
      content: '';
      position: absolute;
      top:0;
      margin: 0 -1px; /* tweak */
      width: 100%;
      height: 100%;
      background: rebeccapurple;
    }
    .shape-shop-image-sash:before {
      right:100%;
    }

  }
}


//////NORMAL
//@media only screen and (min-width: $breakpoint-width-tablet) {
//
//
//  .gallery-container {
//    //display: grid;
//    //grid-template-columns: repeat(3, 1fr);
//    //gap: 10px;
//    //padding: 20px;
//  }
//
//  .gallery-text1 {
//    font-size: 16px;
//    font-weight: bold;
//  }
//  .gallery-text2 {
//    font-size: 16px;
//    //font-size: 30px;
//  }
//  .gallery-text3 {
//    font-style: italic;
//  }
//
//  .gallery-grid-item {
//    padding: 10%;
//  }
//
//  .gallery-modal {
//    .gallery-modal-content {
//      top: 50%;
//      left: 50%;
//      width: 40%;
//      height: auto;
//      transform: translate(-50%, -50%);
//      padding: 20px;
//    }
//  }
//}
//
//
//////TABLET
//@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {
//  .gallery-container {
//    //display: grid;
//    grid-template-columns: repeat(2, 1fr);
//    gap: 2px;
//    padding: 2px;
//  }
//  .gallery-text1 {
//    font-size: 1.5rem;
//    //color: red;
//  }
//  .gallery-text2 {
//    font-size: 0.8rem;
//    //font-size: 30px;
//    font-weight: bold;
//  }
//  .gallery-text3 {
//    font-size: 1rem;
//    font-style: italic;
//  }
//
//  .gallery-grid-item {
//    padding: 5%;
//  }
//
//  .gallery-modal {
//    .gallery-modal-content {
//      top: 50%;
//      left: 50%;
//      width: 100%;
//      height: auto;
//      transform: translate(-50%, -50%);
//      padding: 2px;
//    }
//  }
//
//}
//
////PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  .gallery-container {
    //display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding: 0px;
  }
  .gallery-text1 {

    font-size: 1.1rem;
    //font-size: 30px;
    //color: red;
  }
  .gallery-text2 {
    font-size: 0.7rem;
    //font-size: 20px;
    //font-size: 30px;

  }
  .gallery-text3 {
    font-size: 0.7rem;
    //font-size: 20px;
  }

  .gallery-modal {
    .gallery-modal-content {
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      padding: 2px;
    }
  }
}




