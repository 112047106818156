@import "../App.scss";

.rounded-button {
  display: inline-block;
  padding: 10px 200px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 60px; /* Adjust the border-radius to control the roundness */
  //background-color: #3498db; /* Change the background color */
  color: #fff; /* Change the text color */
  border: 2px solid #c65376; /* Change the border color */
  //color: #2980b9; /* Change the text color on hover */
  color: #c65376;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Add hover effect */
.rounded-button:hover {
  background-color: #eeacb2; /* Change the background color on hover */
  color: #fff; /* Change the text color on hover */
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
}

.grid-item {
  /*background-color: #ddd;*/
  /*border: 1px solid #333;*/
  padding: 4%;
  text-align: center;
}

.grid-item.large {
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.grid-item.medium {
  grid-column-end: span 2;
}

.grid-item.threewide {
  grid-column-end: span 3;
}


img {
  max-width: 100%;
  height: auto;
}

img.hidden {
  opacity: 0;
  transition: all 1s;
  filter: blur(5px);
  transform: translateX(-100px);
}

img.show-1 {
  background-color: white;
  z-index: 2;
  opacity: 1;
  transition: all 2s;
  filter: blur(0);
  transform: translateX(0);
}

img.show-2 {
  background-color: white;
  z-index: 2;
  opacity: 1;
  transition: all 2s;
  filter: blur(0);
  transform: translateX(0);
}

//TABLET
@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {
  .grid-container {
    grid-template-columns: 40% 60%;
    gap: 10px;
    padding: 20px;
  }
  .grid-item.threewide {
    grid-column-end: span 2;
  }
}

//PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 2px;
    padding: 2px;
  }
}
