//$breakpoint-width-phone: 400px;
//$breakpoint-width-tablet: 840px;
//$breakpoint-width-normal: 800px;
@import "Global.scss";

//@font-face { font-family: Delicious; src: ""; }
@font-face { font-family: OstrichSans; font-weight: normal; src: url('font/OstrichSans-Medium.otf');}
//@import "node_modules/";
.App {
  /*border: 5px solid green;*/
}

li, a {
  font-family: "OstrichSans", serif;
}

.App-header {
  display: flex;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  position: sticky;
  background-color: #ffffff;
  padding: 10px;
  z-index: 100;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
  /*border: 5px solid blue;*/

  margin-left:20%;
  margin-right:20%;

}

.App-title {
  /*border: 5px solid gray;*/
}


/* Style the main menu */
.main-menu {
  text-align: right;
  /*border: 5px solid yellow;*/
}

/* Style the menu items */
.main-menu ul {
  padding: 0;
  list-style-type: none;
}

.main-menu li {
  display: inline-block;
  margin: 0 15px;
}

.main-menu a {
  text-decoration: none;
  color: #c65376;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

/* Change link color on hover */
.main-menu a:hover {
  color: #ff9900;
}

.container {
  /*width: 100%;*/

  //border: 5px solid pink;

  background: rgba(255, 98, 0, 0.07);
  margin-left:20%;
  margin-right:20%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border: 5px solid red;*/
}


.wf-active .site-title {
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-family: "OstrichSans", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 74px;
  line-height: 1.25;
}





//TABLET
@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {
  .App-header {
    margin-left: 2px;
    margin-right: 2px;
  }
  .container {
    margin-left: 2px;
    margin-right: 2px;
  }
  h1 {
    font-size: 18px;
  }
  .App-header {
    font-size: 16px;
  }

  .picture-description {
    font-size: 16px;
  }

}

//PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  .App-header {
    margin-left: 2px;
    margin-right: 2px;
  }

  .App-header {
    font-size: 12px;
  }

  .container {
    margin-left: 2px;
    margin-right: 2px;
  }

  h1 {
    font-size: 16px;
  }

  .picture-description {
    font-size: 12px;
  }
}


img {
  max-width: 100%;
  height: auto;
}

